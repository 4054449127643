/* builder.css */
body {
    background-color: var(--body-color);
}

.builder-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 95vh;
}

.builder-content {
    width: 100%;
    margin-left: 300px;
}

.hidden {
    display: none;
}

.section,
.footer {
    background-color: var(--sidebar-color);
    margin: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section {
    padding: 24px;
}

.builder-box {
    margin: 1.5rem auto;
    padding: 0 2rem 2rem;
    /* Remove top padding */
    background-color: var(--sidebar-color);
    /* Ensure background is white */
    box-shadow: none;
    /* Remove box shadow */
    border-radius: 0.5rem;
    margin-left: 35px;
    margin-right: 30px;
    margin-top: 15px;
    max-height: calc(100vh - 100px);
    /* Set max height */
    overflow-y: auto;
    /* Allow scrolling */
}

.footer {
    background-color: var(--primary-color-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
}

.footer span {
    color: var(--text-color);
}

.footer button {
    background-color: var(--secondary-color-text);
    color: var(--sidebar-color);
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.1s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.1s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: var(--secondary-color-text);
}

input:checked + .slider:before {
    transform: translateX(14px);
}

.builder-nav-bar {
    display: flex;
    justify-content: space-around;
    padding: 8px;
    /* Smaller padding */
    border-bottom: 1px solid #e2e8f0;
    /* Add border to separate navbar */
    border-radius: 0px;
    background-color: var(--sidebar-color);
    /* Remove background color */
    box-shadow: none;
    /* Remove box shadow */
}

.builder-nav-bar button {
    background: none;
    border: none;
    color: var(--text-color);
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    transition: background-color 0.3s;
}

.builder-nav-bar button i {
    margin-right: 8px;
}

.builder-nav-bar button:hover {
    background-color: var(--primary-color-light);
}

.builder-nav-bar button.active {
    font-weight: bold;
    border-bottom: 2px solid var(--secondary-color-text);
}

.inner-nav-bar {
    display: flex;
    justify-content: space-around;
    padding: 4px;
    /* Smaller padding */
    border-bottom: 1px solid #e2e8f0;
    /* Add border to separate navbar */
    background-color: var(--sidebar-color);
    /* Remove background color */
    box-shadow: none;
    /* Remove box shadow */
}

.inner-nav-bar button {
    background: none;
    border: none;
    color: var(--text-color);
    cursor: pointer;
    font-size: 14px;
    /* Smaller font size */
    display: flex;
    align-items: center;
    padding: 4px 6px;
    /* Smaller padding */
    transition: background-color 0.3s;
}

.inner-nav-bar button i {
    margin-right: 8px;
}

.inner-nav-bar button:hover {
    background-color: var(--primary-color-light);
}

.inner-nav-bar button.active {
    font-weight: bold;
    border-bottom: 2px solid var(--secondary-color-text);
}

.page-content {
    background-color: var(--sidebar-color);
    padding: 24px;
    margin: 0;
    /* Remove margins to connect to the navbar */
    border-radius: 0 0 8px 8px;
    max-height: calc(100vh - 200px);
    /* Fixed height to fill the entire page */
    overflow-y: auto;
    /* Allow scrolling */
}
