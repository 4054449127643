@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

:root {
    --tran-03: all 0.2s ease;
    --tran-04: all 0.3s ease;
    --tran-05: all 0.3s ease;
}

body {
    min-height: 100vh;
    background-color: var(--body-color);
    transition: var(--tran-05);
}

.theme-light {
    --body-color: #e4e9f7;
    --sidebar-color: #fff;
    --primary-color: #695cfe;
    --primary-color-light: #f6f5ff;
    --primary-color-light-selected: #ecf5ff;
    --secondary-color-text: #2563eb;
    --toggle-color: #ddd;
    --text-color: #707070;
}

.theme-dark {
    --body-color: #18191a;
    --sidebar-color: #242526;
    --primary-color: #3a3b3c;
    --primary-color-light: #3a3b3c;
    --primary-color-light-selected: #30313c;
    --secondary-color-text: #1a91ff;
    --toggle-color: #fff;
    --text-color: #ccc;
}

.app-container {
    display: flex;
}

.main-content {
    flex: 1;
    padding: 20px;
    margin-left: 250px; /* This should be equal to the width of the sidebar */
}

.footer {
    background-color: var(--primary-color-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
}

.footer span {
    color: var(--text-color);
}

.footer button {
    background-color: var(--secondary-color-text);
    color: var(--sidebar-color);
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
