body {
    background-color: var(--body-color);
    margin: 0;
    padding: 0;
}

.protections-container {
    background-color: var(--sidebar-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 32px;
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
}

.protections-header {
    text-align: center;
    margin-bottom: -20px;
}

.form-icon {
    width: 48px;
    height: 48px;
    background-color: var(--primary-color-light);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px;
}

.form-icon i {
    color: var(--secondary-color-text);
    font-size: 1.5rem;
}

.form-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 16px;
}

.form-description {
    color: var(--text-color);
    margin-bottom: 32px;
}

.section {
    background-color: var(--primary-color-light);
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
}

.section .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-left: 16px;
    padding-top: 16px;
    padding-left: 16px;
}

.section .header .title {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: var(--secondary-color-text);
}

.section .header .title i {
    margin-right: 8px;
}

.items {
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.item-controls {
    display: flex;
    align-items: center;
    gap: 16px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.1s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.1s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: var(--secondary-color-text);
}

input:checked + .slider:before {
    transform: translateX(14px);
}

.form-buttons {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    gap: 16px;
}

.save-button,
.next-button {
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--secondary-color-text);
    color: #fff;
    font-size: 1rem;
    text-decoration: none;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: var(--secondary-color-text);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.default-info {
    margin-left: 8px;
}
