body {
    background-color: var(--body-color);
    margin: 0;
    padding: 0;
}

.history-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
}

.history-container {
    background-color: var(--sidebar-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 32px;
    width: 100%;
    text-align: center;
    margin: 0 10px; /* Ensures some space from the sides */
    height: 100%;
}

.history-header {
    margin-bottom: 32px;
}

.reports-container {
    padding: 2rem;
    background-color: var(--sidebar-color);
}

.reports-container h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-color);
    padding-bottom: 1.25rem;
}

.reports-container table {
    width: 100%;
    line-height: normal;
}

.reports-container thead th {
    padding: 0.5rem 1rem;
    border-bottom: 2px solid #e2e8f0;
    text-align: left;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text-color);
    letter-spacing: wider;
}

.reports-container tbody tr td {
    padding: 1.25rem 1.25rem;
    border-bottom: 1px solid #e2e8f0;
    color: var(--text-color);
    font-size: 0.875rem;
}

.reports-container .text-blue-500 {
    color: #4299e1;
}

.controls {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    margin: 20px 10px;
}

.controls button {
    padding: 0.5rem 1rem;
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
}

.controls button:disabled {
    background-color: #cbd5e0;
    cursor: not-allowed;
}

.controls button:hover:not(:disabled) {
    background-color: #3182ce;
}

.app-link {
    color: var(--secondary-color-text);
    text-decoration: none;
}

.app-link:hover {
    text-decoration: underline;
}

.protection-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.protection-option {
    background-color: var(--primary-color-light);
    color: var(--secondary-color-text);
    padding: 4px 8px;
    border-radius: 4px;
    margin: 2px;
    font-size: 0.875rem;
}
