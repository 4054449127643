body {
    background-color: var(--body-color);
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.processing-container {
    background-color: var(--sidebar-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 32px;
    max-width: 600px;
    width: 100%;
    text-align: center;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.processing-header {
    margin-bottom: 32px;
}

.form-icon {
    width: 48px;
    height: 48px;
    background-color: var(--primary-color-light);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px;
}

.form-icon i {
    color: var(--secondary-color-text);
    font-size: 1.5rem;
}

.form-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 16px;
}

.form-description {
    color: var(--text-color);
    margin-bottom: 32px;
}

.progress-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.progress-bar-container {
    width: 70%;
    height: 20px;
    background-color: var(--toggle-color);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.progress-bar-container .progress-bar {
    height: 100%;
    background-color: var(--secondary-color-text);
    transition: width 0.5s ease-in-out;
}

.progress-text {
    margin-left: 10px;
    font-size: 14px;
    color: var(--text-color);
}

.form-buttons {
    margin-top: 32px;
}

.next-button {
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--secondary-color-text);
    color: #fff;
    font-size: 1rem;
    text-decoration: none;
}
