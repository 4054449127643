body {
    background-color: var(--body-color);
    margin: 0;
    padding: 0;
}

.password-reset-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
}

.password-reset-container {
    background-color: var(--sidebar-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 32px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    margin: auto;
}

.password-reset-header {
    text-align: center;
    margin-bottom: 32px;
}

.form-icon {
    width: 48px;
    height: 48px;
    background-color: var(--primary-color-light);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px;
}

.form-icon i {
    color: var(--secondary-color-text);
    font-size: 1.5rem;
}

.form-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 16px;
}

.form-description {
    color: var(--text-color);
    margin-bottom: 32px;
}

.form-group {
    margin-bottom: 24px;
}

.form-group label {
    display: block;
    color: var(--text-color);
    margin-bottom: 8px;
}

.form-group input {
    width: 100%;
    padding: 8px 16px;
    border: 1px solid var(--toggle-color);
    border-radius: 4px;
    background-color: var(--primary-color-light);
    color: var(--text-color);
}

.error-message {
    color: red;
    margin-bottom: 16px;
}

.message {
    color: green;
    margin-bottom: 16px;
}

.form-buttons {
    margin-top: 32px;
}

.reset-password-button {
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--secondary-color-text);
    color: #fff;
    font-size: 1rem;
    text-decoration: none;
}
