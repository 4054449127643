.progress-bar {
    width: 100%;
    height: 24px;
    background-color: #f3f3f3;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    margin-bottom: 16px;
}

.progress {
    height: 100%;
    border-radius: 4px;
    transition: width 0.3s ease-in-out;
}

.progress-bar-text {
    position: absolute;
    width: 100%;
    text-align: center;
    line-height: 24px; /* Same as the height of the progress bar */
    font-size: 0.875rem;
    top: 0;
    left: 0;
    transition: color 0.3s ease-in-out;
}
