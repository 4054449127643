body {
    background-color: var(--body-color);
    margin: 0;
    padding: 0;
}

.extras-container {
    background-color: var(--sidebar-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 32px;
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
}

.extras-header {
    text-align: center;
    margin-bottom: -20px;
}

.form-icon {
    width: 48px;
    height: 48px;
    background-color: var(--primary-color-light);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px;
}

.form-icon i {
    color: var(--secondary-color-text);
    font-size: 1.5rem;
}

.form-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 16px;
}

.form-description {
    color: var(--text-color);
    margin-bottom: 32px;
}

.extras-section {
    background-color: var(--primary-color-light);
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.extras-section .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-left: 16px;
    padding-top: 16px;
    padding-left: 16px;
}

.extras-section .header .title {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: var(--secondary-color-text);
}

.extras-section .header .title i {
    margin-right: 8px;
}

.extras-section .content {
    text-align: center;
    color: var(--text-color);
}

.extras-section .content p {
    margin: 0;
    font-size: 1rem;
}

.extras-section .content .caution {
    color: red;
    font-size: 0.875rem;
    margin: 16px 0;
    white-space: pre-line;
}

.inputs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
}

.input-group {
    display: flex;
    align-items: center;
    gap: 8px;
}

.input-group input {
    flex: 1;
    padding: 8px;
    border: 1px solid var(--toggle-color);
    border-radius: 4px;
    color: var(--text-color);
    font-size: 1rem;
}

.input-group .add-button,
.input-group .remove-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: var(--secondary-color-text);
    color: #fff;
    cursor: pointer;
}

.items-list-box-container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.items-list-box {
    flex: 1;
    border: 1px solid var(--toggle-color);
    border-radius: 4px;
    padding: 8px;
    background-color: var(--sidebar-color);
    max-height: 150px; /* optional: to limit the height of the list */
    overflow-y: auto; /* optional: to add scrollbar if items exceed max-height */
}

.items-list-box.empty {
    height: 0px;
    visibility: hidden;
}

.items-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.items-list .item {
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.items-list .item.selected {
    background-color: var(--primary-color-light-selected);
}

.extras-buttons {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    gap: 16px;
}

.next-button {
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--secondary-color-text);
    color: #fff;
    font-size: 1rem;
    text-decoration: none;
}
