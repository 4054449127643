body {
    background-color: var(--body-color);
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.download-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
}

.download-container {
    background-color: var(--sidebar-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 32px;
    max-width: 600px;
    width: 100%;
    text-align: center;
    margin: 0 10px; /* Ensures some space from the sides */
}

.download-header {
    margin-bottom: 32px;
}

.form-icon {
    width: 48px;
    height: 48px;
    background-color: var(--primary-color-light);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px;
}

.form-icon i {
    color: var(--secondary-color-text);
    font-size: 1.5rem;
}

.form-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 16px;
}

.form-description {
    color: var(--text-color);
    margin-bottom: 32px;
}

.form-buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
}

.download-button,
.finish-button {
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: none;
    transition: background-color 0.3s;
}

.download-button {
    background-color: var(--secondary-color-text);
    color: #fff;
}

.finish-button {
    background-color: var(--primary-color);
    color: #fff;
}

.download-button:hover,
.finish-button:hover {
    opacity: 0.9;
}
