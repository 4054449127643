.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    background-color: var(--primary-color-light);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
}

.item .text-with-icon {
    display: flex;
    align-items: center;
    color: var(--text-color);
}

.item .default-info-container {
    display: flex;
    align-items: center;
    gap: 30px;
}

.disabled-item {
    opacity: 0.6; /* Slightly grey out the item */
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-left: 8px;
    color: var(--secondary-color-text);
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: var(--secondary-color-text);
    color: var(--sidebar-color);
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    line-height: 1.4;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--secondary-color-text) transparent transparent
        transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip.default-info .tooltiptext {
    left: auto;
    right: 0; /* Position the default tooltip on the right */
    margin-left: 0;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.1s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.1s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: var(--secondary-color-text);
}

input:checked + .slider:before {
    transform: translateX(14px);
}

.select-handling-type {
    padding: 8px 12px;
    border: 1px solid var(--toggle-color);
    border-radius: 4px;
    background-color: var(--primary-color-light);
    color: var(--text-color);
    margin-left: 24px; /* Increased space between the dropdown and toggle */
    cursor: pointer;
    transition: background-color 0.2s;
    width: 185px;
}

.select-handling-type:hover {
    background-color: var(--secondary-color-light);
}

.select-handling-type:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
}
