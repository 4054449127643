body {
    background-color: var(--body-color);
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.builder-sidebar {
    width: 300px;
    background-color: var(--sidebar-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
}

.aside-title {
    font-size: 1.65rem;
    font-weight: bold;
    color: var(--secondary-color-text);
    margin-bottom: 20px;
}

.back-button-aside {
    padding: 6px 17px;
    margin-bottom: 70px;
    border: none;
    border-radius: 4px;
    background-color: var(--body-color);
    color: var(--text-color);
    cursor: pointer;
    text-decoration: none;
}

.aside-list {
    color: var(--text-color);
}

.aside-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}

.aside-list-item:last-child {
    margin-bottom: 0;
}

.aside-list-item-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 16px;
    text-decoration: none;
}

.aside-list-item-number.active {
    background-color: var(--secondary-color-text);
    color: #fff;
    cursor: pointer;
}

.aside-list-item-number.inactive {
    background-color: var(--body-color);
    color: var(--text-color);
}

.aside-list-item-content h4 {
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
}

.aside-list-item-content p {
    font-size: 0.875rem;
    margin: 0;
}
