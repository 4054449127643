.reports-container {
    /* margin: 1.5rem auto; */
    padding: 2rem;
    background-color: var(--sidebar-color);
    /* box-shadow:
        0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    */
}

.reports-container h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-color);
    padding-bottom: 1.25rem;
}

.reports-container table {
    width: 100%;
    line-height: normal;
}

.reports-container thead th {
    padding: 0.5rem 1rem;
    border-bottom: 2px solid #e2e8f0;
    text-align: left;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text-color);
    letter-spacing: wider;
}

.reports-container tbody tr td {
    padding: 1.25rem 1.25rem;
    border-bottom: 1px solid #e2e8f0;
    color: var(--text-color);
    font-size: 0.875rem;
}

.reports-container .text-blue-500 {
    color: #4299e1;
}

.controls {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    margin-left: 270px;
    margin-right: 45px;
    margin-top: 20px;
    margin-bottom: 35px;
}

.controls input[type="text"],
.controls select,
.controls button {
    padding: 0.5rem 1rem;
    border: 2px solid #e2e8f0;
    background: var(--sidebar-color);
    color: var(--text-color);
    border-radius: 0.375rem;
    font-size: 0.875rem;
}

.controls button {
    padding: 0.5rem 1rem;
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
}

.controls button:hover {
    background-color: #3182ce;
}
