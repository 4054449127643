body {
    background-color: var(--body-color);
    margin: 0;
    padding: 0;
}

.dashboard {
}

.dashboard-header {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-color);
    padding: 1.25rem 1.15rem;
    margin-top: -15px;
}

.home-section .home-content {
    position: relative;
    padding-top: 104px;
}

.home-content .overview-boxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-bottom: 26px;
}

.overview-boxes .box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 4 - 15px);
    background: var(--sidebar-color);
    padding: 15px 14px;
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.overview-boxes .box-topic {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: var(--text-color);
}

.home-content .box .number {
    font-size: 25px;
    margin-top: 5px;
    text-align: center;
    font-weight: 500;
    color: var(--text-color);
}

.home-content .box .indicator {
    display: flex;
    align-items: center;
}

.home-content .box .indicator i {
    height: 20px;
    width: 20px;
    background: #8fdacb;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    margin-right: 5px;
}

.box .indicator i.down {
    background: #e87d88;
}

.home-content .box .indicator .text {
    font-size: 12px;
}

.home-content .box .cart {
    display: inline-block;
    font-size: 32px;
    height: 50px;
    width: 50px;
    background: #cce5ff;
    line-height: 50px;
    text-align: center;
    color: #66b0ff;
    border-radius: 12px;
    margin: -15px 0 0 6px;
}

.home-content .box .cart.two {
    color: #2bd47d;
    background: #c0f2d8;
}
.home-content .box .cart.three {
    color: #ffc233;
    background: #ffe8b3;
}
.home-content .box .cart.four {
    color: #e05260;
    background: #f7d4d7;
}
.home-content .total-order {
    font-size: 20px;
    font-weight: 500;
}
.home-content .sales-boxes {
    display: flex;
    justify-content: space-between;
}

.home-content .sales-boxes .recent-builds {
    width: 65%;
    background: var(--sidebar-color);
    padding: 20px 30px;
    margin: 0 20px;
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.home-content .sales-boxes .builds-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sales-boxes .box .title {
    font-size: 21px;
    font-weight: 500;
    color: var(--text-color);
}

.sales-boxes .builds-details li.topic {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color);
}

.sales-boxes .builds-details li {
    list-style: none;
    margin: 8px 0;
    color: var(--text-color);
}

.sales-boxes .box .button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.sales-boxes .box .button a {
    color: #fff;
    background: var(--secondary-color-text);
    padding: 4px 12px;
    font-size: 15px;
    font-weight: 400;
    margin-top: 10px;
    border-radius: 4px;
    text-decoration: none;
    transition: all 0.3s ease;
}

.sales-boxes .box .button a:hover {
    background: #0d3073;
}

.home-content .sales-boxes .top-sales {
    width: 35%;
    background: var(--sidebar-color);
    color: var(--text-color);
    padding: 20px 30px;
    margin: 0 20px 0 0;
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.sales-boxes .top-sales li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.sales-boxes .top-sales li a img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 12px;
    margin-right: 10px;
}

.sales-boxes .top-sales li a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.sales-boxes .top-sales li .product,
.price {
    font-size: 17px;
    font-weight: 400;
    color: var(--text-color);
    margin-top: 10px;
}
